<template>
  <button
    type="button"
    class="btn btn-info btn-sm font-size-sm poppins"
    @click="() => onClick(item.variable)"
  >
    {{ item.name }}
  </button>
</template>

<script>
export default {
  props: ["item", "insert"],
  methods: {
    onClick(text) {
      this.insert(text);
    },
  },
};
</script>

<style scoped>
.copied {
  top: -20px;
}
</style>
